import React from 'react'
import PropTypes from 'prop-types' 
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Container } from '../components/styles/Layout.styles'
import Row from '../components/Row'
import Column from '../components/Column'
import Article from '../components/ArticlesHome'

import H4 from '../components/H4'
import Div from '../components/Div/Div.styles'
import OutlineButton from '../components/OutlinedButton'
import Hr from '../components/Hr'
import {
  contentPost,
  dots,
} from '../components/styles/PostBlog.styles'
import { normalizeDate } from '../utils/helpers';
import Helmet from 'react-helmet'
import { DOMParser } from 'xmldom'

export const PageTemplate = ({
  data
}) => {
  const { wordpressPost: post } = data

  const posts = data.allWordpressPost.edges

  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Container>
          <Div
            maxWidth={['100%', 390, 600]}
            alignItems={['center', 'center', 'stretch']}
          >
            <Row
              pt={[50]}
              pb={[16]}
              mx={[-16, -16, -24, -24]}
            >
              <Column
                width={[1]}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Div
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='left'
                  maxWidth={['100%', 344, '100%']}
                >
                  <H4
                    selected
                    on='onSurface'
                    variant='highEmphasis'
                    style={{
                      width: '100%'
                    }}
                    dangerouslySetInnerHTML={{
                      __html:  (post.title)
                    }}
                  />
                  <Div
                    style={{
                      fontSize: 11.8,
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      color: 'rgba(0, 0, 0, 0.6)',
                      textTransform: 'uppercase',
                      marginTop: 16
                    }}
                  >
                    {normalizeDate(post.date)} • Leitura 5 min
                  </Div>
                </Div>
              </Column>
            </Row>
            <Row
              flexDirection={['column']}
              css={contentPost}
            >
              <Column
                mt={[30]}
                mb={[50]}
                px={[null]}
                dangerouslySetInnerHTML={{
                  __html: post.content
                }}
              />
            </Row>
            <Row
              mb={[50]}
            >
              <Div
                textAlign={['center']}
                justifyContent={['center']}
                flexDirection={['row']}
              >
                <div css={dots} />
                <div css={dots} />
                <div css={dots} />
              </Div>
            </Row>
          </Div>
        </Container>
        <Container>
          <Div
            maxWidth={['100%', 390, 813]}
            alignItems={['center', 'center', 'stretch']}
          >
            <Row
              pb={[50, 70]}
              mx={[-16, -16, -24, -24]}
            >
              <Column
                width={[1]}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Div
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='left'
                  maxWidth={['100%', 422]}
                >
                  <H4
                    selected
                    on='onSurface'
                    variant='highEmphasis'
                    style={{
                      marginBottom: 6
                    }}
                  >
                    Posts<br />Relacionados
                  </H4>
                </Div>
                {posts.length > 0
                  && Array(Math.ceil(posts.length / 2)).slice(0, 2).fill(0).map((item, i) => {
                    return (
                      <Row
                        key={`post-row-${i}`}
                        flexDirection={['column']}
                        maxWidth={['100%', 422]}
                        width={['100%']}
                      >
                        {posts.slice(i === 0 ? 0 : i * 2, 2 + i * 2).map((post, x) => (
                          <Column
                            key={`post-item-${x + i}`}
                            width={[1,]}
                            mt={[24]}
                          >
                            <Article
                              title={new DOMParser().parseFromString(post.node.title,'text/html').childNodes[0].textContent}
                              slug={post.node.slug}
                              meta={{
                                date: post.node.date,
                                duration: '6 min'
                              }}
                              img={post.node.featured_media && post.node.featured_media.localFile.publicURL}
                            />
                            <Hr
                              lineColor='lightPeriwinkle'
                              maxWidth={[50]}
                              style={{
                                marginLeft: 0
                              }}
                            />
                          </Column>
                        ))}
                      </Row>
                    )
                })}
                <div
                  style={{
                    marginTop: 50
                  }}
                >
                  <OutlineButton
                    type='btnOutlinedLarge'
                    Link='/blog'
                  >
                    voltar para o blog
                  </OutlineButton>
                </div>
              </Column>
            </Row>
          </Div>
        </Container>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
   
  const dom = new DOMParser().parseFromString(data.wordpressPost.title,'text/html');
  const decodedTitle = dom.childNodes[0].textContent;
    
  return (
    <Layout>
      <Seo 
        path={'/blog/'+data.wordpressPost.slug}
        title={data.wordpressPost.title}
        description={data.wordpressPost.excerpt}
        image={data.wordpressPost.featured_media && data.wordpressPost.featured_media.localFile.childImageSharp.resolutions}
      />
      <Helmet>
        <title>{`${decodedTitle}`}</title>
      </Helmet>
      <PageTemplate
        title='Blog'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD MM YYYY")
    title
    featured_media {
      localFile {
        publicURL
      }
    }
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      date(formatString: "DD MM YYYY")
      categories {
        name
        slug
      }
      featured_media {
        localFile {
          childImageSharp{
            resolutions(width: 500, height: 500) {
              src
              width
              height
            }
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    image1: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
